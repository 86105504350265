<template>
  <v-dialog v-model="bDialog" max-width="700">
    <template #activator="{ on }">
      <v-list color="grey lighten-3" rounded>
        <v-subheader>
          {{ $t("assign.branches") }}
          <v-spacer/>
          <v-progress-circular v-if="loading" :size="20" :width="2" color="primary" indeterminate/>
          <v-btn v-else color="green" icon v-on="on">
            <icon-add size="1.2em"/>
          </v-btn>
        </v-subheader>

        <template v-for="obCompany in arSelectedCompanyBranchList">
          <v-subheader :key="`user-company-assigned-${obCompany.id}`">
            {{ obCompany.firm.name }}
          </v-subheader>
          <template v-for="obBranch in obCompany.branches">
            <branch-item-preview :key="`user-assigned-company-${obBranch.id}`" :item="obBranch" hide-title v-on="on"/>
          </template>
        </template>
      </v-list>
    </template>

    <v-card v-if="bDialog" :loading="loading">
      <v-list v-if="arSelectedCompanyList.length" class="active" dark>
        <template v-for="(item, index) in arSelectedCompanyList">
          <v-list-item
            :key="`companies-added-list-item-${index}`"
            @click="del(item)"
          >
            <v-list-item-avatar>
              <icon-camera outlined size="1.8rem"/>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title v-text="item.firm.name"/>
              <v-list-item-subtitle v-text="item.firm.email"/>
            </v-list-item-content>
            <v-list-item-action>
              <icon-remove class="red--text" size="1.5em"/>
            </v-list-item-action>
          </v-list-item>
        </template>
      </v-list>

      <v-list v-for="obCompany in arSelectedCompanyBranchList" :key="`company-branches-list-${obCompany.id}`" class="active"
              dark>
        <v-subheader>
          {{ obCompany.firm.name }}
        </v-subheader>
        <template v-for="(item, index) in obCompany.branches">
          <branch-item-preview
            :key="`companies-added-list-item-${index}`"
            :item="item"
            hide-default-icon
            hide-icon
            @click="del(item)">
            <template #action>
              <icon-remove class="red--text" size="1.5em"/>
            </template>
          </branch-item-preview>
        </template>
      </v-list>

      <v-card-text>
        <v-text-field
          ref="searchField"
          v-model="sSearch"
          :label="$t('search.company')"
          :placeholder="$t('search.company')"
        ></v-text-field>
      </v-card-text>

      <v-card-text v-if="arCompanyCollectionList.length">
        <v-expansion-panels>
          <template v-for="(item, index) in arCompanyCollectionList">
            <company-branches-list
              :key="`company-branches-list-${index}`"
              :ar-branch-list="arBranchList"
              :item="item"
              @selection-applied="handleSelectionApplied"
            />
          </template>
        </v-expansion-panels>
      </v-card-text>

      <v-card-actions>
        <v-spacer/>
        <v-btn color="primary" depressed small @click="bDialog = false">
          {{ $t("close") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import type {Branch, BranchData, CompanyData} from "@planetadeleste/vue-mc-gw";
import {BranchCollection, CompanyCollection} from "@planetadeleste/vue-mc-gw";
import {Component, Prop, VModel, Vue, Watch} from "vue-property-decorator";
import BranchItemPreview from "@/modules/companies/components/BranchItemPreview.vue";
import type {DebounceFunction} from "@/plugins/helpers";
import {debounce, filter, find, forEach, uniq, uniqBy} from "lodash";
import CompanyBranchesList from "./CompanyBranchesList.vue";

@Component({
  components: {
    CompanyBranchesList,
    BranchItemPreview,
  },
})
export default class CompanyBranchesPicker extends Vue {
  bDialog = false;
  sSearch = "";
  fnDebounceSearch!: DebounceFunction;
  obCompanyCollection = new CompanyCollection();
  obBranchCollection = new BranchCollection();

  @VModel({type: Array, default: () => []}) arBranchList!: number[];
  @Prop(Boolean) readonly singleOption!: string;

  arBranchListId: number[] = [];
  selectedBranches: any[] = [];
  loading = false;

  get arSelectedCompanyList(): BranchData[] {
    //CompanyData[] {
    //const arItems = this.obCompanyCollection.getModelList() as CompanyData[];
    const arItems = this.obBranchCollection.getModelList() as BranchData[];
    return this.arBranchListId.length && arItems.length
      ? filter(arItems, (obItem) => this.arBranchListId.includes(obItem.id))
      : [];
  }

  get arSelectedBranchList(): BranchData[] {
    return this.selectedBranches;
  }

  get arSelectedCompanyBranchList(): CompanyData[] {
    const arCompanyList: CompanyData[] = [];
    forEach(this.arSelectedBranchList, obBranch => {
      let obCompany: CompanyData | null | undefined = null;

      if (!obBranch.company || filter(arCompanyList, obItem => obItem.branches?.length && !!find(obItem.branches, {id: obBranch.id})).length) {
        return;
      }

      obCompany = find(arCompanyList, {id: obBranch.company.id}) as CompanyData | undefined;

      if (!obCompany) {
        obCompany = obBranch.company;
        obCompany.branches = [];
        arCompanyList.push(obCompany);
      }

      obCompany.branches.push(obBranch);
    });

    return arCompanyList;
  }

  get arCompanyCollectionList(): CompanyData[] {
    return this.obCompanyCollection.getModelList() as CompanyData[];
    // const arItems = this.obCompanyCollection.getModelList() as CompanyData[];
    //
    // return this.arBranchListId.length
    //   ? filter(arItems, (obItem) => !this.arBranchListId.includes(obItem.id))
    //   : arItems;
  }

  @Watch("sSearch")
  onSearch() {
    this.fnDebounceSearch();
  }

  @Watch("bDialog")
  onOpenClose(bValue: boolean) {
    if (bValue) {
      this.load();
    } else {
      this.sSearch = "";
    }
  }

  created() {
    this.fnDebounceSearch = debounce(this.search, 500);
  }

  mounted() {
    this.$nextTick(this.load);
  }

  async load() {
    await this.search(true);

    if (!this.arBranchList.length) {
      return;
    }

    this.loading = true;
    this.arBranchListId = [...this.arBranchList];
    const obBranchCollection = new BranchCollection();
    obBranchCollection.page(1);
    await obBranchCollection.filterBy({set: this.arBranchListId}).fetch();
    this.selectedBranches = obBranchCollection.models;
    this.loading = false;
  }

  async search(force: boolean = false) {
    if (!this.bDialog && !force) {
      return;
    }

    this.loading = true;
    const obCollection = new CompanyCollection();
    obCollection.page(1);

    if (this.sSearch.length > 1) {
      obCollection.filterBy({search: this.sSearch});
    }

    await obCollection.fetch();
    this.obCompanyCollection.clear();
    this.obCompanyCollection.add(obCollection.models);
    this.loading = false;
  }

  del(obBranch: BranchData) {
    const idx = this.selectedBranches.findIndex(
      (branch) => branch.id === obBranch.id
    );

    if (idx < 0) {
      return;
    }

    this.selectedBranches.splice(idx, 1);
    this.arBranchListId = this.selectedBranches.map((branch) => branch.id);
    this.apply();
  }

  apply(close: boolean = false) {
    this.$emit(
      "input",
      this.arBranchListId.length ? uniq(this.arBranchListId) : []
    );

    if (close) {
      this.bDialog = false;
    }
  }

  handleSelectionApplied(selectedBranches: Branch[], unselectedBranchIds: number[] = []) {
    this.selectedBranches.push(...selectedBranches);

    if (unselectedBranchIds.length) {
      this.selectedBranches = this.selectedBranches.filter(obItem => !unselectedBranchIds.includes(obItem.id))
    }

    this.selectedBranches = uniqBy(this.selectedBranches, "id");
    this.arBranchListId = this.selectedBranches.map((branch) => branch.id);
    this.apply();
  }
}
</script>
